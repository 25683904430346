.background-quick-color {
    background-color: #037ABE;
    color: #FFFFFF;
}

.background-plus-color {
    background-color: #D1721F;
    color: #FFFFFF;
}

.background-normal-color {
    background-color: #BFE52E;
    color: #FFFFFF;
}

.border-gray {
    border-color: darkgray !important;
    border-width: 4px !important;
}
