.scrollable-section-home {
  height: 100vh;
  overflow-y: auto;
}

.image-container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
}

.img-col-home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  margin-top: 3rem;
}

.card-title-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5em;
  text-align: center;
}

.card-title-home:hover {
  width: 200px;
  height: 200px;
  border: 0.5rem solid #b5ed36a0;
  border-radius: 50%;
}

.align-home {
  display: flex;
  align-items: center;
  justify-content: center;
}
