.text-custom-blue {
    color: #037abe;
}

.text-custom-green {
    color: #bfe52e;
}

.sign-in-border {
    border-bottom: 1px solid;
    width: 50%;
}

.link-create-account {
    border-bottom: 1px solid;
    width: 40%;
}

.background-image-sign-in {
    background-image: url("../../../assets/img/backgrounds/iniciar-sesion.jpg");
}
