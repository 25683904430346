.scrollable-section-orders {
  background-color: #CCCCCC;
  height: 100vh;
}

.container-orders{
  display: flex;
}

.child-orders-img{
  flex: 1
}

.circular-image-container-orders {
  width: 50%;
  height: 50%;
}


.circular-image-orders {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title-orders {
  color: white;
  font-size: 2vh;
}

@media only screen and (max-width: 850px) {
  .card-title-orders {font-size: 0.5vh;}
} 


.borderless-button-orders{
  border: none;
  outline: none; /* Optional: removes the focus outline */
  background-color: transparent; /* Optional: makes the background transparent */
  padding: 0; /* Optional: removes default padding */
  cursor: hover; /* Optional: makes cursor change to pointer on hover */
  /* Add any other custom styles here */
}
